/* issue with firefox not printing more than 1 page */
.pine {
  body {
    display: block;
  }
  /* Removes the gray box around the Contact Us info */
  .view-group-information table {
    border: 0;
  }
  /* Removes the orange bar above Contact Info */
  #footer {
    border-top-width: 0;
  }
}

.paragraphs-items {
  .paragraphs-item-paragraph-1-col-clean {
    padding: 1.5em 5%;
  }
  /* One Column w/ Background removes min height */
  .paragraphs-item-paragraph-1-col {
    min-height: 0;
    display: block;
    .field-items {
      /* Getting the text to center for 1-column */
      text-align: left;
    }
    /* Stops 1-column text from floating on the right hand side */
    /* Setting the width to 100% for when our 1-column is not already to the left */
    &.p-right {
      .content {
        width: 100%;
        float: none;
        .field-items {
          padding: 0;
        }
      }
    }
    &.p-left {
      .content {
        width: 100%;
        .field-items {
          padding: 0;
        }
      }
    }
  }
  /* Add some spacing and fixing the widths of the three column */
  .paragraphs-item-paragraph-3-col {
    .span4:nth-child(n) {
      margin: 10px;
      max-width: 30%;
    }
  }
  /* Removes a lot of white space above and below a Two Column paragraph */
  .row-fluid.paragraph-2-col {
    .span6 {
      width: 100%;
      min-height: 0;
    }
  }
  .row-fluid.paragraph-2-col {
    display: block;
  }
  /* Some padding cleanup to get stuff nice and snug to the left */
  .field-item .field-item,
  .span12,
  .span6 {
    padding: 0;
  }
  .paragraph-menu {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
body,
a,
.live-feeds-block abbr[title],
.pine .live-feeds-block .calendar-item .date.calendar .day,
.pine .live-feeds-block .calendar-item .date.calendar .year,
.live-feeds-block .calendar-item .description,
.live-feeds-block .calendar-item .description,
.pine .live-feeds-block .news-item h3.title a strong,
.pine .live-feeds-block .calendar-item .title a strong,
.pine .live-feeds-block .news-item .date,
.pine .live-feeds-block .news-item .news-body,
strong {
  color: black !important;
}

/* Removes padding from below the Contact Us section */
#sidebar-first .region-sidebar-first > div,
#sidebar-second .region-sidebar-second > div {
  padding-bottom: 0;
}

/* Removes parallaxed images */
.parallax-mirror {
  display: none;
}

/* Remove mobile menu.*/
#mobile-icon-menu {
  display: none !important;
}

iframe {
  &[src*=mediaspace],
  &[src*=vimeo],
  &[src*=youtube] {
    display: none;
  }
}
